import React, { useState } from "react";

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import ReservationPopup from "./Popup";
import ReservationCardMobile from "./ReservationCardMobile"; // Import du composant mobile

// Styles personnalisés
const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "#ffffff",
  width: "100%",
  borderRadius: "8px",
  marginBottom: "30px",
  "& .MuiSelect-select": {
    padding: "15px",
    "&.Mui-focused": {
      outline: `2px solid #d7a022`,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    border: `1px solid #d7a022`,
    "&:hover": {
      border: "2px solid #d7a022",
      backgroundColor: "white",
    },
    "&.Mui-focused": {
      border: `2px solid #d7a022`,
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const DateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: "1px solid transparent", // Définit une bordure initialement transparente
  "&:hover": {
    border: "2px solid #d7a022",
    backgroundColor: "white",
  },
}));

const ReservationCard = ({ e }) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Utiliser la taille d'écran mobile

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isMobile) {
    return <ReservationCardMobile e={e} onClick={handleClickOpen} />; // Passer les données et la fonction onClick
  }

  return (
    <Box
      sx={{
        width: "508px",
        height: "656px",
        borderRadius: "20px",
        boxShadow: 3,
        marginLeft: "33px",
        marginRight:"300px",
        p: 3,

        "& .MuiFormControl-root": {
          width: "100%",
          mb: 2,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "18px",
          mb: 3,
          mt: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            display: "flex", // Utiliser flex pour l'alignement en ligne
            alignItems: "center", // Centrer verticalement les éléments
          }}
        >
          {e.prixParInvite} MAD
          <Typography
            component="span"
            sx={{
              fontFamily: "Poppins",
              display: "inline",
              fontWeight: "400",
              marginLeft: "4px",
              fontSize: "16px",
            }}
          >
            / par invité
          </Typography>
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
            ml: -3,
            mr: 3, // Ajuste cette valeur pour décaler le texte vers la gauche
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "inline",
              fontWeight: "400",
              marginLeft: "4px",
              fontSize: "16px",
            }}
          >
            {e.rating == 0 ? 5 : e.rating}/5
            <Typography
              component="span"
              sx={{
                fontFamily: "Poppins",
                display: "inline",

                marginLeft: "4px",
                fontSize: "14px",
              }}
            >
              (500)
            </Typography>
          </Typography>
          <Box
            sx={{
              ml: 0.5,
            }}
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "4px" }} // Ajuste cette valeur pour l'espacement entre l'étoile et le texte
            >
              <path
                d="M9.96929 0.572764C10.1659 0.101153 10.8341 0.101153 11.0307 0.572764L13.2046 5.78638C13.2907 5.9928 13.4887 6.13059 13.7121 6.13962L19.7676 6.38422C20.3141 6.4063 20.524 7.10698 20.0797 7.4259L15.4401 10.7556C15.2396 10.8996 15.1533 11.1554 15.2256 11.3914L16.823 16.5999C16.9772 17.1026 16.4316 17.5314 15.9796 17.2629L10.7937 14.1818C10.6127 14.0743 10.3873 14.0743 10.2063 14.1818L5.02041 17.2629C4.56841 17.5314 4.02283 17.1026 4.17699 16.5999L5.77435 11.3914C5.84672 11.1554 5.76041 10.8996 5.55989 10.7556L0.920322 7.4259C0.475957 7.10699 0.68586 6.4063 1.23238 6.38422L7.28785 6.13962C7.51132 6.13059 7.70928 5.9928 7.79536 5.78638L9.96929 0.572764Z"
                fill="#FFE711"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "#F5F5F5",
          width: "437px",
          height: "443px",
          borderRadius: "20px",
          padding: "35px",
          gap: "10px",
          marginLeft: "10px",
          textAlign: "left",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Nombre d'invités
        </Typography>
        <Select
          defaultValue={2}
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <MenuItem value={2}>2 invités</MenuItem>
          <MenuItem value={1}>1 invité</MenuItem>
          <MenuItem value={3}>3 invités</MenuItem>
          <MenuItem value={4}>4 invités</MenuItem>
        </Select>

        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Date de réservation
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", mb: 4 }}>
          <DateButton
            variant="text"
            sx={{
              color: "black",
              fontFamily: "Poppins",
              textTransform: "none",
              fontWeight: "400",
              border: "1px solid #B0B0B0",
              padding: "12px 16px",
            }}
          >
            13 Mai
          </DateButton>
          <DateButton
            variant="text"
            sx={{
              color: "black",
              fontFamily: "Poppins",
              textTransform: "none",
              fontWeight: "400",
              border: "1px solid #B0B0B0",
              padding: "8px 16px",
            }}
          >
            14 Mai
          </DateButton>
          <DateButton
            variant="text"
            sx={{
              color: "black",
              fontFamily: "Poppins",
              textTransform: "none",
              fontWeight: "400",
              border: "1px solid #B0B0B0",
              padding: "8px 16px",
            }}
          >
            15 Mai
          </DateButton>
          <DateButton
            variant="text"
            sx={{
              color: "black",
              fontFamily: "Poppins",
              textTransform: "none",
              fontWeight: "400",
              border: "1px solid #B0B0B0",
              padding: "8px 16px",
            }}
          >
            16 Mai
          </DateButton>
        </Box>

        <CustomSelect
          id="date"
          name="date"
          value="2024-05-13T13:00:00"
          variant="outlined"
          fullWidth
        >
          <MenuItem value="2024-05-13T13:00:00">
            Lundi 13/05/2024 - 13h00
          </MenuItem>
        </CustomSelect>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "16px",
          }}
        >
          Annulation sans frais jusqu'à 1 jour avant la date de réservation.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#d7a022",
            width: "380px",
            padding: "10px",
            borderRadius: "10px",
            fontFamily: "Poppins",
            textTransform: "none",
            fontSize: "18px",
            "&:hover": { backgroundColor: "#d7a022" },
          }}
          onClick={handleClickOpen}
        >
          Réserver
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <ReservationPopup /> {/* Affichez le composant `Popup` ici */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReservationCard;
