import { Button } from "@mui/base/Button";
import DrawerAppBar from "./components/DrawerAppBar";
import { Search } from "@mui/icons-material";
import SearchBy from "./components/SearchBy";
import Experiences from "./components/Experiences";
import { Box } from "@mui/material";
import Plats from "./components/Plats";
import TopHote from "./components/TopHote";
import Carousel from "./components/TestimonialCarousel";
import TestimonialCarousel from "./components/TestimonialCarousel";
import Footer from "./components/Footer";
import { Container } from "@mui/system";
import { isEdge, isFirefox, isChrome } from "react-device-detect";
import { AddReview } from "./components/ReviewModal/AddReview";

import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
export function Home() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  if(keycloak.authenticated){
    console.log("Authenticated");
    navigate("/hostProfile");
  }
  return (
    <>
    
        <DrawerAppBar />
      
      <Container>

        <Box
        >
          <Experiences />
          <Plats />
          <AddReview />
          <TopHote />
        </Box>
        <TestimonialCarousel />
      </Container>
      <Footer />
    </>
  );
}
