import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, TextField, Box, Typography, Grid, IconButton, Dialog,
    DialogActions, DialogContent, DialogTitle, Button
} from '@mui/material';
import { CheckCircle, Block, RateReview } from '@mui/icons-material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Book as BookIcon, Work as WorkIcon, InsertEmoticon as InsertEmoticonIcon, MusicNote as MusicNoteIcon, StarOutline as StarOutlineIcon, EmojiObjects as EmojiObjectsIcon, Favorite as FavoriteIcon, AutoStories as AutoStoriesIcon, Language as LanguageIcon, LocationOn as LocationOnIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Edit as EditIcon, Delete as DeleteIcon, Block as BlockIcon } from '@mui/icons-material';
import AboutSection from './AboutSection';

function Hotes() {
    const [hosts, setHosts] = useState([]);
    const [filteredHosts, setFilteredHosts] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // State for the selected host for the dialog
    const [selectedHost, setSelectedHost] = useState(null);
    const [open, setOpen] = useState(false);

    // Individual filters for each attribute
    const [filterNom, setFilterNom] = useState('');
    const [filterPrenom, setFilterPrenom] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterCity, setFilterCity] = useState('');
    const [filterRating, setFilterRating] = useState('');

    useEffect(() => {
        const fetchHosts = async () => {
            let loadingToast;
            try {
                loadingToast = toast.loading("Loading hosts data...");
                const res = await axios.get(`${process.env.REACT_APP_API_SER}api/host`);
                setHosts(res.data);
                setFilteredHosts(res.data); // Set both hosts and filtered hosts
                toast.update(loadingToast, { render: "Hosts data loaded!", type: "success", isLoading: false, autoClose: 2000 });
            } catch (error) {
                toast.update(loadingToast, { render: "Failed to load hosts data.", type: "error", isLoading: false, autoClose: 2000 });
                console.error('Error fetching hosts:', error);
            }
        };

        fetchHosts();
    }, []);

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle Filtering Logic
    useEffect(() => {
        const filteredData = hosts.filter((host) =>
            host.nom.toLowerCase().includes(filterNom.toLowerCase()) &&
            host.prenom.toLowerCase().includes(filterPrenom.toLowerCase()) &&
            host.email.toLowerCase().includes(filterEmail.toLowerCase()) &&
            (host.city ? host.city.toLowerCase().includes(filterCity.toLowerCase()) : true) &&
            (host.rating ? host.rating.toString().includes(filterRating) : true)
        );
        setFilteredHosts(filteredData);
        setPage(0); // Reset to the first page after filtering
    }, [filterNom, filterPrenom, filterEmail, filterCity, filterRating, hosts]);

    // Open Dialog with specific host details
    const handleOpenDialog = (host) => {
        setSelectedHost(host);
        setOpen(true);
    };

    // Close Dialog
    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedHost(null); // Clear selected host after closing dialog
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                List of Hosts (Hôtes)
            </Typography>

            {/* Advanced Filter Inputs */}
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by Last Name (Nom)"
                        variant="outlined"
                        fullWidth
                        value={filterNom}
                        onChange={(e) => setFilterNom(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by First Name (Prénom)"
                        variant="outlined"
                        fullWidth
                        value={filterPrenom}
                        onChange={(e) => setFilterPrenom(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by Email"
                        variant="outlined"
                        fullWidth
                        value={filterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by City"
                        variant="outlined"
                        fullWidth
                        value={filterCity}
                        onChange={(e) => setFilterCity(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by Rating"
                        variant="outlined"
                        fullWidth
                        value={filterRating}
                        onChange={(e) => setFilterRating(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>

            {/* Table Display */}
            <TableContainer component={Paper}>
                <Table aria-label="hosts table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Avatar</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Is Enabled</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredHosts
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((host) => (
                                <TableRow key={host.id}>
                                    <TableCell>
                                        <img
                                            src={host.avatar ? `${process.env.REACT_APP_API_SER}photos/${host.avatar}` : 'https://img.icons8.com/?size=100&id=23264&format=png&color=000000'}
                                            alt={`Avatar`}
                                            style={{
                                                width: 50,
                                                height: 50,
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                                marginRight: '10px',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{host.id}</TableCell>
                                    <TableCell>{host.nom}</TableCell>
                                    <TableCell>{host.prenom}</TableCell>
                                    <TableCell>{host.email}</TableCell>
                                    <TableCell>{host.numTele || 'N/A'}</TableCell>
                                    <TableCell>{host.city || 'N/A'}</TableCell>
                                    <TableCell>{host.rating || 'N/A'}</TableCell>
                                    <TableCell>
                                        {host.isEnabled ? (
                                            <CheckCircle style={{ color: 'green' }} />
                                        ) : (
                                            <Block style={{ color: 'red' }} />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {/* Review Button */}
                                        <IconButton color="primary" aria-label="review" onClick={() => handleOpenDialog(host)}>
                                            <RateReview />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                {/* Pagination Controls */}
                <TablePagination
                    component="div"
                    count={filteredHosts.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </TableContainer>

            {/* Dialog to show host details */}
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
  <DialogTitle>Host Details</DialogTitle>
  <DialogContent>
    {selectedHost && (
      <Box display="flex" flexDirection="row">
        {/* Left side: Avatar and basic info */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={2}
          borderRight="1px solid #ccc"
          minWidth="250px"
        >
          <img
            src={selectedHost.avatar ? `${process.env.REACT_APP_API_SER}photos/${selectedHost.avatar}` : 'https://img.icons8.com/?size=100&id=23264&format=png&color=000000'}
            alt={`Avatar of ${selectedHost.nom}`}
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              objectFit: 'cover',
              marginBottom: '15px',
            }}
          />
          <Typography variant="h6">{selectedHost.nom} {selectedHost.prenom}</Typography>
          <Typography variant="body2" color="textSecondary">{selectedHost.city}</Typography>
          <Typography variant="body2" color="textSecondary">Member for {selectedHost.membershipYears || 'N/A'} years</Typography>
        </Box>

        {/* Right side: Detailed information */}
        <Box padding={2} width="100%">
          <Typography variant="h6">À propos</Typography>

          <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            <AboutSection selectedHost={selectedHost} />

  {/* Enhanced buttons in a row */}
  <Box display="flex" gap={2} marginTop={2}>
    <Button 
      variant="contained" 
      color="primary" 
      startIcon={<EditIcon />} 
    >
      Edit
    </Button>
    <Button 
      variant="contained" 
      color="secondary" 
      startIcon={<DeleteIcon />} 
    >
      Delete
    </Button>
    <Button 
      variant="contained" 
      color="warning" 
      startIcon={<BlockIcon />} 
      disabled={!selectedHost.isEnabled}
    >
      Disable
    </Button>
    <Button 
      variant="contained" 
      color="success" 
      startIcon={<CheckCircleIcon />} 
      disabled={selectedHost.isEnabled}
    >
      Enable
    </Button>
  </Box>
</Box>

        </Box>
      </Box>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">Close</Button>
  </DialogActions>
</Dialog>


            {/* Toast Container for notifications */}
            <ToastContainer />
        </Box>
    );
}

export default Hotes;
