import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, TextField, Box, Typography, Grid, IconButton, Dialog,
    DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem
} from '@mui/material';
import { RateReview, Edit as EditIcon, Delete as DeleteIcon, Block as BlockIcon, CheckCircle as CheckCircleIcon, ArrowBack, ArrowForward } from '@mui/icons-material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Experience() {
    const [experiences, setExperiences] = useState([]);
    const [filteredExperiences, setFilteredExperiences] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [open, setOpen] = useState(false);
    const [filterCity, setFilterCity] = useState('');
    const [selectedCity, setSelectedCity] = useState('Rabat');
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const fetchExperiences = async () => {
            let loadingToast;
            try {
                loadingToast = toast.loading("Loading experiences...");
                const res = await axios.get(`${process.env.REACT_APP_API_SER}experience/0/120`);
                setExperiences(res.data);
                setFilteredExperiences(res.data);
                toast.update(loadingToast, { render: "Experiences loaded!", type: "success", isLoading: false, autoClose: 2000 });
            } catch (error) {
                toast.update(loadingToast, { render: "Failed to load experiences.", type: "error", isLoading: false, autoClose: 2000 });
                console.error('Error fetching experiences:', error);
            }
        };
        fetchExperiences();
    }, []);

    useEffect(() => {
        const filteredData = experiences.filter((exp) =>
            (exp.city ? exp.city.toLowerCase().includes(filterCity.toLowerCase()) : true)
        );
        setFilteredExperiences(filteredData);
        setPage(0);
    }, [filterCity, experiences]);

    const handleOpenDialog = (experience) => {
        setSelectedExperience(experience);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedExperience(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setImagePreviewOpen(true);
    };

    const handleCloseImagePreview = () => {
        setImagePreviewOpen(false);
    };

    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? selectedExperience.photos.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === selectedExperience.photos.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                List of Experiences
            </Typography>

            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Filter by City"
                        variant="outlined"
                        fullWidth
                        value={filterCity}
                        onChange={(e) => setFilterCity(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        fullWidth
                    >
                        {[...new Set(experiences.map(exp => exp.city))].map(city => (
                            <MenuItem key={city} value={city}>
                                {city}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table aria-label="experiences table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredExperiences
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((exp) => (
                                <TableRow key={exp.id}>
                                    <TableCell>{exp.id}</TableCell>
                                    <TableCell>{exp.titre}</TableCell>
                                    <TableCell>{exp.description}</TableCell>
                                    <TableCell>{exp.lieu || 'N/A'}</TableCell>
                                    <TableCell>{exp.rating || 'N/A'}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" aria-label="review" onClick={() => handleOpenDialog(exp)}>
                                            <RateReview />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={filteredExperiences.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </TableContainer>

            {/* Dialog for Detailed View */}
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>Experience Details</DialogTitle>
                <DialogContent>
                    {selectedExperience && (
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Typography variant="h5">{selectedExperience.titre}</Typography>
                            <Typography>{selectedExperience.description}</Typography>
                            <Typography>City: {selectedExperience.lieu}</Typography>
                            <Typography>Price per Guest: {selectedExperience.prixParInvite} MAD</Typography>
                            <Typography>Max Guests: {selectedExperience.nombreMaxInvite}</Typography>
                            <Typography>Rating: {selectedExperience.rating || 'N/A'}</Typography>
                            <Typography>Start Time: {selectedExperience.heurDebut}</Typography>
                            <Typography>End Time: {selectedExperience.heurFin}</Typography>
                            <Typography>Cuisine Type: {selectedExperience.typeCuisine}</Typography>
                            <Typography>Inspiration: {selectedExperience.inspiration}</Typography>
                            <Typography>Precautions: {selectedExperience.precautions}</Typography>

                            <Box marginTop={2}>
                                <Typography variant="h6">Host Details</Typography>
                                <Typography>Host Name: {selectedExperience.host.prenom} {selectedExperience.host.nom}</Typography>
                                <Typography>Host Bio: {selectedExperience.host.bio}</Typography>
                                <Typography>Host City: {selectedExperience.host.city}</Typography>
                                <Typography>Contact: {selectedExperience.host.numTele}</Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    overflowX: 'auto',
                                    gap: 2,
                                    padding: '10px 0'
                                }}
                            >
                                {selectedExperience.photos.map((photo, index) => (
                                    <Box
                                        key={photo.id}
                                        component="img"
                                        src={`${process.env.REACT_APP_API_SER}photos/${photo.id}`}
                                        alt={`Experience ${selectedExperience.titre}`}
                                        onClick={() => handleImageClick(index)}
                                        sx={{
                                            width: '150px',
                                            height: 'auto',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Box display="flex" gap={2} marginTop={2}>
                                <Button variant="contained" color="primary" startIcon={<EditIcon />}>Edit</Button>
                                <Button variant="contained" color="secondary" startIcon={<DeleteIcon />}>Delete</Button>
                                <Button variant="contained" color="warning" startIcon={<BlockIcon />} disabled={!selectedExperience.isEnabled}>Disable</Button>
                                <Button variant="contained" color="success" startIcon={<CheckCircleIcon />} disabled={selectedExperience.isEnabled}>Enable</Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            {/* Full-Screen Image Preview Dialog with Navigation */}
            <Dialog open={imagePreviewOpen} onClose={handleCloseImagePreview} maxWidth="lg" fullWidth>
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                        <IconButton onClick={handlePreviousImage} sx={{ position: 'absolute', left: 16 }}>
                            <ArrowBack />
                        </IconButton>
                        <img
                            src={`${process.env.REACT_APP_API_SER}photos/${selectedExperience?.photos[currentImageIndex].id}`}
                            alt="Experience Preview"
                            style={{ width: '80%', borderRadius: '8px' }}
                        />
                        <IconButton onClick={handleNextImage} sx={{ position: 'absolute', right: 16 }}>
                            <ArrowForward />
                        </IconButton>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseImagePreview} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <ToastContainer />
        </Box>
    );
}

export default Experience;
