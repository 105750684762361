import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useReducer } from "react";
function experienceReducer(state, action) {
  switch (action.type) {
    case 'SET_EXPERIENCE':
      return action.payload || state;
    default:
      return state;
  }
}
const ExperienceCard = ({ e }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [experience, dispatch] = useReducer(experienceReducer, null);

  // Use an effect to set 'e' when the prop changes
  useEffect(() => {
    if (e) {
      dispatch({ type: 'SET_EXPERIENCE', payload: e });
    }
  }, [e]);

  // If no 'e' prop and no experience in state, handle gracefully
  if (!experience) {
    return <div>No experience data available</div>;
  }
  return (
    <Box style={{margin: "0px auto", width:"90%"}}>
      <Card style={{ borderRadius: "20px", border: "1px solid #7C7C7C" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            sx={{ mb: 2, padding: isMobile ? "10px" : "30px" }}
          >
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? "45" : "65"}
                  height={isMobile ? "45" : "65"}
                  viewBox="0 0 29 28"
                  fill="none"
                >
                  <path
                    d="M13.6023 7.47404V5.75333H10.9343C10.6984 5.75333 10.4722 5.66429 10.3054 5.5058C10.1387 5.3473 10.045 5.13234 10.045 4.90819C10.045 4.68404 10.1387 4.46908 10.3054 4.31059C10.4722 4.15209 10.6984 4.06305 10.9343 4.06305H18.0491C18.2849 4.06305 18.5111 4.15209 18.6779 4.31059C18.8447 4.46908 18.9384 4.68404 18.9384 4.90819C18.9384 5.13234 18.8447 5.3473 18.6779 5.5058C18.5111 5.66429 18.2849 5.75333 18.0491 5.75333H15.381V7.47404C17.0873 7.58974 18.7502 8.03832 20.2659 8.7918C21.7817 9.54528 23.1178 10.5875 24.1909 11.8534C25.264 13.1193 26.0511 14.5818 26.5031 16.1496C26.9551 17.7174 27.0623 19.3569 26.818 20.9659H2.16536C1.92105 19.3569 2.02823 17.7174 2.48023 16.1496C2.93222 14.5818 3.71933 13.1193 4.79245 11.8534C5.86557 10.5875 7.20168 9.54528 8.71743 8.7918C10.2332 8.03832 11.8961 7.58974 13.6023 7.47404ZM3.81954 19.2756H25.1638C25.1638 17.9438 24.8878 16.625 24.3514 15.3945C23.8151 14.1641 23.029 13.0461 22.038 12.1043C21.047 11.1626 19.8705 10.4156 18.5757 9.90589C17.2809 9.39622 15.8932 9.13389 14.4917 9.13389C13.0902 9.13389 11.7024 9.39622 10.4076 9.90589C9.11283 10.4156 7.93634 11.1626 6.94534 12.1043C5.95434 13.0461 5.16824 14.1641 4.63191 15.3945C4.09559 16.625 3.81954 17.9438 3.81954 19.2756ZM2.9302 22.6562H26.0532C26.289 22.6562 26.5152 22.7452 26.682 22.9037C26.8488 23.0622 26.9425 23.2771 26.9425 23.5013C26.9425 23.7254 26.8488 23.9404 26.682 24.0989C26.5152 24.2574 26.289 24.3464 26.0532 24.3464H2.9302C2.69433 24.3464 2.46812 24.2574 2.30134 24.0989C2.13455 23.9404 2.04085 23.7254 2.04085 23.5013C2.04085 23.2771 2.13455 23.0622 2.30134 22.9037C2.46812 22.7452 2.69433 22.6562 2.9302 22.6562Z"
                    fill="#E3B13E"
                  />
                </svg>
                <Typography
                  variant="caption"
                  align="center"
                  sx={{
                    fontSize: isMobile ? "14px" : "16px",
                    fontFamily: "Poppins",
                  }}
                >
                  Déjeuner
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? "45" : "65"}
                  height={isMobile ? "45" : "65"}
                  viewBox="0 0 34 32"
                  fill="none"
                >
                  <path
                    d="M3.96082 24.3401C5.4737 21.9421 8.78945 20.3009 13.9625 20.3009C19.1355 20.3009 22.4513 21.9421 23.9642 24.3401M24.9212 18.4825C27.6607 19.7841 29.0304 21.0858 30.4002 23.6891M22.0721 8.53319C23.3611 9.19214 24.2373 10.486 24.2373 11.9744C24.2373 13.4198 23.411 14.6818 22.1827 15.357M18.0717 11.9744C18.0717 14.131 16.2319 15.8794 13.9625 15.8794C11.693 15.8794 9.85328 14.131 9.85328 11.9744C9.85328 9.81774 11.693 8.06943 13.9625 8.06943C16.2319 8.06943 18.0717 9.81774 18.0717 11.9744Z"
                    stroke="#E3B13E"
                    stroke-width="1.74481"
                    stroke-linecap="round"
                  />
                </svg>
                <Typography
                  variant="caption"
                  align="center"
                  sx={{
                    fontSize: isMobile ? "14px" : "16px",
                    fontFamily: "Poppins",
                  }}
                >
                  {e.nombreMaxInvite} invités maximum
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? "45" : "65"}
                  height={isMobile ? "45" : "65"}
                  viewBox="0 0 30 29"
                  fill="none"
                >
                  <path
                    d="M19.6218 17.8532C20.0483 17.9883 20.5036 17.7521 20.6387 17.3256C20.7738 16.899 20.5375 16.4438 20.111 16.3087L19.6218 17.8532ZM15.6707 15.7519H14.8606C14.8606 16.105 15.0894 16.4175 15.4261 16.5241L15.6707 15.7519ZM16.4808 10.1945C16.4808 9.74711 16.1181 9.38442 15.6707 9.38442C15.2233 9.38442 14.8606 9.74711 14.8606 10.1945H16.4808ZM20.111 16.3087L15.9153 14.9796L15.4261 16.5241L19.6218 17.8532L20.111 16.3087ZM16.4808 15.7519V10.1945H14.8606V15.7519H16.4808ZM26.0492 14.4228C26.0492 19.8091 21.442 24.2452 15.6707 24.2452V25.8653C22.258 25.8653 27.6693 20.7808 27.6693 14.4228H26.0492ZM15.6707 24.2452C9.89944 24.2452 5.29227 19.8091 5.29227 14.4228H3.67209C3.67209 20.7808 9.08347 25.8653 15.6707 25.8653V24.2452ZM5.29227 14.4228C5.29227 9.03652 9.89944 4.60049 15.6707 4.60049V2.98032C9.08347 2.98032 3.67209 8.06488 3.67209 14.4228H5.29227ZM15.6707 4.60049C21.442 4.60049 26.0492 9.03652 26.0492 14.4228H27.6693C27.6693 8.06488 22.258 2.98032 15.6707 2.98032V4.60049Z"
                    fill="#E3B13E"
                  />
                </svg>
                <Typography
                  variant="caption"
                  align="center"
                  sx={{
                    fontSize: isMobile ? "14px" : "16px",
                    fontFamily: "Poppins",
                  }}
                >
                  {e.heurDebut}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            {e.host.titreBiographique}
          </Typography>
          <br />
          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            • {e.descriptionHost}
          </Typography>

          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            • Une table exclusive de {e.nombreMaxInvite} convives
          </Typography>
          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            • {e.inspiration}
          </Typography>
          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            • {e.intercation}
          </Typography>
          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            • {e.precautions}
          </Typography>

          <br />

          <Typography
            fontFamily={"Poppins"}
            fontWeight="600"
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            A propos de votre hôte {e.host.prenom} {e.host.nom}:
          </Typography>
          <br />
          <Typography
            fontFamily={"Poppins"}
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            {e.host.anecdote}
          </Typography>
          <br />
          
          <Typography
            fontFamily={"Poppins"}
            fontWeight="600"
            marginLeft={"48px"}
            marginRight={"40px"}
          >
            Pourquoi nous aimons cette expérience:
          </Typography>
          <br />
          <Box>
            {[
            ].map((text, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                mb={{ xs: 2, md: 3 }}
                marginLeft={"38px"}
              >
                <Box mr={2} mt={{ xs: -4, md: -3 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={isMobile ? "24" : "32"}
                    height={isMobile ? "24" : "32"}
                    viewBox="0 0 14 14"
                    fill="none"
                    style={{ marginRight: "-14px" }}
                  >
                    <rect
                      width="13.3115"
                      height="12.6499"
                      transform="translate(0 0.851624)"
                      fill="white"
                    />
                    <path
                      d="M4.09657 9.1699L2.61719 7.39464L4.07872 9.58694C4.42234 10.1024 5.17866 10.1055 5.5265 9.59287L8.94211 4.55933L5.46135 9.13928C5.12201 9.58578 4.4556 9.60073 4.09657 9.1699Z"
                      fill="#D7A022"
                      stroke="#D7A022"
                      stroke-width="0.436202"
                    />
                  </svg>
                </Box>
                <Typography
                  style={{ fontFamily: "Poppins", marginRight: "40px" }}
                >
                  {text}
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExperienceCard;
