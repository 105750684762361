import Footer from "../Footer";
import ExperienceCard from "./ExperienceCard";
import MenuSection from "./MenuITem";
import Nav from "./Nav";
import ReservationCard from "./ReservationCard";
import ReviewResults from "./Review";
import styled from "styled-components";
import PlatCard from "./PlatCard";
import HostMapWithRadius from "./HostMapWithRadius";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 80px); 
  overflow-x: hidden;
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

const FullWidthSection = styled.div`
  width: 100%;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 120px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0rem;
  margin-top: 1rem;
  flex: 1;
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Single column layout on mobile
    justify-items: center;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  @media (max-width: 768px) {
    width: 100%; // Adjust width on mobile
    align-items: center;
    margin-left: 0;
  }
`;

const RightColumn = styled.div`
  position: sticky;
  top: 0.2rem;
  align-self: start;
  bottom: 200px;
  @media (max-width: 768px) {
    margin-left: 0; // Center the reservation card on mobile
    width: 100%; // Full width on mobile
    align-self: center;
  }
`;

const ExperienceCardWrapper = styled.div`
  margin-top: -14px;
  @media (max-width: 768px) {
    width: 100%; // Adjust to full width on mobile
    margin-top: -40px;
    align-self: center;
    margin-left: 0;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 50px;
    text-align: center;
  }
`;

const ReviewResultsWrapper = styled.div`
  
  @media (max-width: 768px) {
    width: 100%;
    align-self: center; // Center reviews on mobile
    margin-left: 0;
    margin-top: 20px;
  }
`;

const HostMapWithRadiusWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%; // Adjust to full width on mobile
    align-self: center;
  }
`;

const MenuSectionWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%; // Adjust to full width
    margin-left: 0;
    margin-top: 20px;
  }
`;

export function Annonce() {
  const [host, setHost] = useState({});
  const [experience, setExperience] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const e = location.state || JSON.parse(localStorage.getItem('eData'));

  // Redirect to the home page or any other page if `e` is null
  useEffect(() => {
    if (!e) {
      navigate("/"); // Redirect to home or any other route if 'e' is not found
    } else {
      // Save 'e' to localStorage so that it persists across reloads
      localStorage.setItem('eData', JSON.stringify(e));
    }
  }, [e, navigate]);

  // Fetch host data when the component mounts
  useEffect(() => {
    if (e && e.host && e.host.id) {
      axios
        .get(process.env.REACT_APP_API_SER + "api/host/" + e.host.id)
        .then((response) => {
          setHost(response.data);
        });
    }
  }, [e]);

  const fields = {
    Category1: [
      { title: "Item 1", description: "Description 1" },
      { title: "Item 2", description: "Description 2" },
    ],
    Category2: [{ title: "Item 3", description: "Description 3" }],
  };

  return (
    <PageWrapper>
      <Nav color="light" />
      <FullWidthSection>
        <PlatCard e={e} />
      </FullWidthSection>
      <ContentWrapper>
        <LeftColumn>
          <ExperienceCardWrapper>
            <ExperienceCard e={e} />
          </ExperienceCardWrapper>
          <MenuSectionWrapper>
            <MenuSection fields={e.menu} />
          </MenuSectionWrapper>
          <ReviewResultsWrapper>
            <ReviewResults />
          </ReviewResultsWrapper>
          <HostMapWithRadiusWrapper>
            <HostMapWithRadius id={e.id} />
          </HostMapWithRadiusWrapper>
        </LeftColumn>
        <RightColumn>
          <ReservationCard e={e} />
        </RightColumn>
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  );
}
