import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import {
  bgcolor,
  color,
  fontSize,
  fontWeight,
  height,
  styled,
} from "@mui/system";
import NavHote from "../../Host/NavHote";
import Footer from "../Footer";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Grid } from "@mui/material";
import {
  Col,
  Form,
  Input,
  Row,
  TimePicker,
  DatePicker as AntDatePicker,
  InputNumber,
  Result,
  Space,
} from "antd";
import dayjs from "dayjs";
import { DatePickerProps } from "antd";
import { Dayjs } from "dayjs";
import { InputNumberProps } from "antd";

import {
  EnvironmentOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import MenuForm from "./MenuForm";
import InformationsComplementaires from "./InfosCom";
import { Link } from "react-router-dom";
import LocationPicker from "../LocationPicker";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#d7a022",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9e9e9e",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Poppins",
    position: "relative",
    fontSize: "14px",
    transform: "none",
    fontWeight: "500",
    color: "#7C7C7C",
    marginBottom: theme.spacing(1),
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: "#e0e0e0",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#d7a022",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9e9e9e",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Poppins",
    position: "relative",
    fontSize: "14px",
    transform: "none",
    fontWeight: "500",
    color: "#7C7C7C",
    transform: "none",
    marginBottom: theme.spacing(1),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const format = "HH:mm";
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const steps = [
  "Informations générales",
  "Informations détaillées",
  "Informations complémentaires",
];

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(4),
}));

const StepperContainer = styled(Stepper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginBottom: "60px",
}));

const StepButtonStyled = styled(StepButton)(({ theme }) => ({
  color: "#B7A694",
  "& .MuiStepIcon-root": {
    color: "#B0B0B0", // Couleur par défaut (gris)
    "&.Mui-active": {
      color: "#D7A24D", // Couleur de l'étape active
    },
    "&.Mui-completed": {
      color: "#d7a022", // Couleur des étapes complétées
    },
  },
  "& .MuiStepLabel-label": {
    color: "#B7A694",
    "&.Mui-active": {
      fontWeight: "bold",
      color: "#d7a022",
    },
    "&.Mui-completed": {
      fontWeight: "bold",
      color: "#d7a022",
    },
  },
}));
const FileUpload = styled(Box)(({ theme }) => ({
  border: "2px dashed #D7A24D", // Dashed border to match your design
  backgroundColor: "white", // Background color
  borderRadius: "8px", // Border radius for rounded corners
  padding: theme.spacing(4), // Padding inside the box
  textAlign: "center", // Center text alignment
  color: "#4B5563", // Font color
  marginBottom: theme.spacing(2), // Margin bottom for spacing
  cursor: "pointer", // Pointer cursor for hover effect
  "&:hover": {
    backgroundColor: "transparent", // Change background on hover
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#D7A24D",
  textTransform: "none",
  fontFamily: "Poppins",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#B79040",
  },
}));

const LinkStyled = styled("span")(({ theme }) => ({
  color: "#D7A24D",
  textDecoration: "underline",
  cursor: "pointer",
}));

export default function NewAnnonce() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const { keycloak } = useKeycloak();
  const [events, setEvents] = useState([]);
  const [finishe, setFinishe] = useState(false);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [location, setLocation] = useState("");

  const [file, setFile] = useState(null);
  const [photoId, setPhotoId] = useState("");
  const [imageData, setImageData] = useState(null);
  const [preview, setPreview] = useState(null);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  useEffect(() => {
    // Store the original viewport settings to restore later
    const originalViewport = document.querySelector("meta[name=viewport]");
    
    // Create a new meta tag to simulate a desktop view on mobile
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=1024'; // Force desktop width
    document.getElementsByTagName('head')[0].appendChild(meta);
    
    // Cleanup function to remove the desktop-like settings when component is unmounted
    return () => {
      document.getElementsByTagName('head')[0].removeChild(meta);
      
      // Restore original viewport settings (if they exist)
      if (originalViewport) {
        document.getElementsByTagName('head')[0].appendChild(originalViewport);
      }
    };
  }, []);
  const [annonceData, setAnnonceData] = useState({
    photos: [],
    reservations: [],
    menu:[],
    titre: "",
    description: "",
    prixParInvite: 0.0,
    localisation: "",
    temps: "2024-08-16T00:00:00.000Z",
    nombreMaxInvite: 0,
    typeCuisine: "",
    nombreAvis: 0,
    rating: 0.0,
    descriptionHost: "",
    lieu: "",
    nombreInvites: 0,
    heurDebut: "13:00",
    heurFin: "",
    inspiration: "",
    intercation: "",
    precautions: "",
    blocReserver: "",
    dureeExperience: "",
  });
  const [city, setCity] = useState('');
  const [error, setError] = useState('');

  const handleMenuChange = (menuData) => {
    const formattedMenu = Object.keys(menuData).flatMap((category, typeIndex) => 
      menuData[category].map((item) => ({
        ...item,
        type: typeIndex + 1, // Ou adaptez selon la logique de catégorisation
      }))
    );
    setAnnonceData((prevData) => ({
      ...prevData,
      menu: formattedMenu,
    }));
    console.log("Formatted menu data:", formattedMenu);
  };
  
  const handleOpenLocationPicker = () => {
    setShowLocationPicker(true);
  };

  const handleCloseLocationPicker = () => {
    setShowLocationPicker(false);
  };

  const fetchCity = async (location) => {
    try {
      const [lat, lon] = location.split(',');
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
  
      if (response.data && response.data.address) {
        const cityName = response.data.address.city || response.data.address.town || response.data.address.village;
        console.log("city name:", cityName);
        setAnnonceData((prevData) => ({
          ...prevData,
          lieu: cityName
        }));
        setLocation(cityName); // Optional, if you need `location` separately
        setError('');
      } else {
        setError('City not found');
      }
    } catch (err) {
      console.error("Error fetching city:", err);
      setError('Error fetching data');
    }
  };
  
  const handleSelectLocation = async (locationString, lieuString) => {
    setAnnonceData((prevData) => ({
      ...prevData,
      localisation: locationString
    }));
    fetchCity(locationString); // Pass the new location
  };
  
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnnonceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setAnnonceData((prevData) => ({
      ...prevData,
      temps: date,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleCompletion = () => {
    setFinishe(true);
    handleUpload();
    /*
    console.log(annonceData);
    axios.post("http://localhost:8080/experience/add", annonceData , {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      }
    })
    .then(response => {
      console.log(response.data);
      setEvents(response.data);
    })
    .catch(error => {
      console.error('Error sending experience:', error);
    });*/
  };

  //handle image upload
  /*
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file); // file should be a File object
    formData.append("experienceCulinaire", JSON.stringify(annonceData)); // experienceCulinaire data

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_SER + "experience/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${keycloak.token}`,
          },
        }
      );
      setPhotoId(response.data.id); // handle response as needed
      alert("Experience added successfully");
    } catch (error) {
      console.error("Error uploading experience", error);
      alert("Error uploading experience");
    }
  };*/

  const handleRetrieve = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_SER + `photos/${photoId}`,
        { responseType: "arraybuffer" }
      );
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      setImageData(`data:image/jpeg;base64,${base64}`);
    } catch (error) {
      console.error("Error retrieving photo", error);
      alert("Error retrieving photo");
    }
  };
  const handleRetrieveAll = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_SER + `photos`
      );
      setImages(response.data);
    } catch (error) {
      console.error("Error retrieving photo", error);
      alert("Error retrieving photo");
    }
  };

  //fin handle functions

  //handle multiple files
  const handleFileChange = (event) => {
    if (files.length == 20) {
      return;
    }
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      // Convert FileList to an array
      const fileArray = Array.from(selectedFiles);

      // Append new files to existing files
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...fileArray];
        return updatedFiles;
      });

      // Generate previews for images
      setPreviews((prevPreviews) => {
        const newPreviews = fileArray.map((file) => URL.createObjectURL(file));
        const updatedPreviews = [...prevPreviews, ...newPreviews];
        return updatedPreviews;
      });

      console.log("Files:", files.length); // Note: The state might not be updated immediately in the console log
      console.log("Number of files:", fileArray.length);
      console.log("files" ,files);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    const formData = new FormData();

    // Append multiple files to FormData
    console.log("Files:", files);
    files.forEach((file) => {
      console.log("File:", file);
      formData.append("file", file); // Use the same key for all files as expected by the backend
    });

    // Append additional form data
    formData.append("experienceCulinaire", JSON.stringify(annonceData)); // experienceCulinaire data

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SER}experience/adds`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${keycloak.token}`, // Replace with actual token handling
          },
        }
      );
      setPhotoId(response.data.id); // Handle response as needed
      alert("Experience added successfully");
    } catch (error) {
      console.error("Error uploading experience", error);
      alert("Error uploading experience");
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Titre de l'expérience*"
                  variant="outlined"
                  placeholder="Quel titre accrocheur et descriptif allez-vous choisir pour votre expérience ?"
                  onChange={(e) =>
                    setAnnonceData({ ...annonceData, titre: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledFormControl fullWidth variant="outlined">
                  <InputLabel shrink>Type de cuisine*</InputLabel>
                  <StyledSelect
                    label="Type de cuisine*"
                    displayEmpty
                    value={annonceData.typeCuisine}
                    onChange={(e) =>
                      setAnnonceData({
                        ...annonceData,
                        typeCuisine: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="" disabled>
                      <span style={{ color: "#9F9C9C", fontSize: "14px" }}>
                        Quel type de cuisine proposez-vous ?
                      </span>
                    </MenuItem>
                    <MenuItem value="Vegan">Cuisine Végane</MenuItem>
<MenuItem value="Vegetarian">Cuisine Végétarienne</MenuItem>
<MenuItem value="GlutenFree">Cuisine Sans Gluten</MenuItem>
<MenuItem value="DairyFree">Cuisine Sans Produits Laitiers (Dairy-Free)</MenuItem>
<MenuItem value="Paleo">Cuisine Paléo</MenuItem>
<MenuItem value="Keto">Cuisine Keto</MenuItem>
<MenuItem value="Halal">Cuisine Halal</MenuItem>
<MenuItem value="Kosher">Cuisine Casher</MenuItem>
<MenuItem value="LowFODMAP">Cuisine Faible en FODMAPs</MenuItem>
<MenuItem value="Macrobiotic">Cuisine Macrobiotique</MenuItem>

<MenuItem value="AllergenFreePeanuts">Sans Arachides</MenuItem>
<MenuItem value="AllergenFreeNuts">Sans Fruits à Coque</MenuItem>
<MenuItem value="AllergenFreeEggs">Sans Œufs</MenuItem>
<MenuItem value="AllergenFreeSoy">Sans Soja</MenuItem>
<MenuItem value="AllergenFreeFish">Sans Poisson</MenuItem>
<MenuItem value="AllergenFreeShellfish">Sans Fruits de Mer</MenuItem>
<MenuItem value="AllergenFreeMustard">Sans Moutarde</MenuItem>
<MenuItem value="AllergenFreeCelery">Sans Céleri</MenuItem>
<MenuItem value="AllergenFreeSesame">Sans Graines de Sésame</MenuItem>
<MenuItem value="AllergenFreeSulfites">Sans Sulfites</MenuItem>
<MenuItem value="VeganGlutenFree">Végane Sans Gluten</MenuItem>
<MenuItem value="VegetarianDairyFree">Végétarienne Sans Produits Laitiers</MenuItem>
<MenuItem value="PaleoPeanutsFree">Paléo Sans Arachides</MenuItem>
<MenuItem value="KetoNutsFree">Keto Sans Fruits à Coque</MenuItem>
<MenuItem value="GlutenDairyFree">Sans Gluten et Sans Produits Laitiers</MenuItem>
<MenuItem value="HalalEggsFree">Cuisine Halal Sans Œufs</MenuItem>
<MenuItem value="KosherSoyFree">Cuisine Casher Sans Soja</MenuItem>

                  </StyledSelect>
                </StyledFormControl>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              multiline
              rows={6}
              label="Description*"
              placeholder="Pouvez-vous décrire en détail l'expérience que vous proposez ?
Quelles sont les principales attractions et l'ambiance générale de votre événement ?"
              name="description"
              value={annonceData.description}
              sx={{
                marginTop: "80px",
                bgcolor: "white",
                borderRadius: "10px",
                marginBottom: "100px",

                "& .MuiOutlinedInput-root": {
                  borderColor: "#B6B6B6",
                  "& fieldset": {
                    borderColor: "#B6B6B6",
                    borderRadius: "10px",
                    borderWidth: "0.5px",
                    borderStyle: "solid",
                  },
                  "&:hover fieldset": {
                    borderColor: "#d7a022",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#9e9e9e",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  transform: "translate(0, -73px)",
                  fontWeight: "500",
                  color: "#7C7C7C",
                  zIndex: 1,
                },
                "& .MuiInputBase-root": {
                  paddingTop: "24px",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
                "& .MuiInputBase-root": {
                  minHeight: "180px", // Ajustez cette valeur en fonction de vos besoins
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                setAnnonceData({ ...annonceData, description: e.target.value })
              }
              margin="normal"
            />
            <div style={{ marginTop: "-30px", marginBottom: "20px" }}>
              <div style={{}}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: 500,
                    lineHeight: "22.5px",
                    textAlign: "left",
                    color: "#7C7C7C",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  Photos*
                </label>
              </div>
              <box style={{ height: "200px" }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    mt: 2,
                    fontFamily: "Poppins",
                    border: "2px dashed #D2D5DA",
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: "10px",
                    textAlign: "center",
                    color: "#4B5563",
                    cursor: "pointer",
                    height: "200px", // Increased height
                    padding: "195px", // Adjust padding to match the height
                    "&:hover": {
                      backgroundColor: "white",
                      boxShadow: "#d7a022",
                    },
                  }}
                >
                  {/* File input hidden */}
                  <input type="file" hidden onChange={handleFileChange} />

                  {/* Upload content */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box mb={2}>
                      {/* Upload Icon */}
                      <div
                        className="svg-container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          //marginLeft: "-30px",
                          marginTop: "-80px",
                        }}
                      >
                        <p
                          className="ant-upload-drag-icon"
                          style={{ marginBottom: "22px" }}
                        >
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 48 47"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M41.3149 28.9351V36.6306C41.3149 37.6511 40.9095 38.6298 40.1879 39.3513C39.4663 40.0729 38.4876 40.4783 37.4671 40.4783H10.5328C9.51233 40.4783 8.53364 40.0729 7.81204 39.3513C7.09045 38.6298 6.68506 37.6511 6.68506 36.6306V28.9351"
                              stroke="#6D7280"
                              strokeWidth="2.40485"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M33.6196 15.4679L24.0003 5.84851L14.3809 15.4679"
                              stroke="#6D7280"
                              strokeWidth="2.40485"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M24 5.84851V28.9351"
                              stroke="#6D7280"
                              strokeWidth="2.40485"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </p>
                      </div>
                    </Box>
                    <div
                      style={{
                        color: "#4B5563",
                        fontSize: "16px",
                        marginBottom: "46px",
                        fontFamily: "Poppins",
                        textTransform: "none",
                      }}
                    >
                      Avez-vous des photos de haute qualité de vos plats, de
                      votre espace de repas et d'autres éléments pertinents à
                      partager ?
                    </div>
                    <br />
                    {/* File Input */}
                    <div
                      style={{
                        color: "#4B5563",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        textTransform: "none",
                      }}
                    >
                      Déposez ici pour joindre ou{" "}
                      <LinkStyled htmlFor="file-upload">
                        <input
                          type="file"
                          id="file-upload"
                          hidden
                          onChange={handleFileChange}
                        />
                        importer
                      </LinkStyled>
                    </div>
                    <br />
                    <div
                      style={{
                        color: "#4B5563",
                        fontSize: "14px",
                        marginTop: "4px",
                        fontFamily: "Poppins",
                        textTransform: "none",
                        marginBottom: "-66px",
                      }}
                    >
                      Maximum 20 photos
                    </div>
                  </Box>
                </Button>

                {/* Image Preview */}
                {preview && (
                  <Box mt={2}>
                    <img src={preview} alt="Preview" style={{ width: "10%" }} />
                  </Box>
                )}
                <Row gutter={16} style={{ marginTop: "20px" }}>
                  {previews.map((preview, index) => (
                    <Col span={8} key={index}>
                      <img
                        src={preview}
                        alt={`preview-${index}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Col>
                  ))}
                </Row>
              </box>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() =>
                  window.open(
                    "https://lien-de-votre-banque-dimages.com",
                    "_blank"
                  )
                }
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  letterSpacing: "-0.02em",
                  textAlign: "center",
                  textDecoration: "underline",
                  padding: "8px 8px",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                  color: "#d7a022",
                  textTransform: "none",
                }}
              >
                Voir banque d'images
              </Button>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <Form layout="vertical" style={{ marginBottom: "30px" }}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Nombre d'invités*">
                    <InputNumber
                      min={1}
                      max={10}
                      value={annonceData.nombreMaxInvite}
                      onChange={(value) =>
                        setAnnonceData({
                          ...annonceData,
                          nombreMaxInvite: value,
                        })
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Tarif par invité*">
                    <InputNumber
                      suffix="MAD"
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        setAnnonceData({ ...annonceData, prixParInvite: value })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Lieu*">
                    <Input
                      value={location}
                      placeholder="Choisir ma localisation"
                      suffix={<EnvironmentOutlined />}
                      style={{ width: "100%" }}
                      onClick={handleOpenLocationPicker}
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Date*">
                    <AntDatePicker
                      onChange={(value) =>
                        setAnnonceData({
                          ...annonceData,
                          temps: value.format("YYYY-MM-DD"),
                        })
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Heure de début*">
                    <TimePicker
                      defaultValue={dayjs("13:00", format)}
                      format={format}
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        setAnnonceData({
                          ...annonceData,
                          heurDebut: value ? value.format(format) : "",
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Heure de fin*">
                    <TimePicker
                      defaultValue={dayjs("13:00", format)}
                      format={format}
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        setAnnonceData({
                          ...annonceData,
                          heurFin: value ? value.format(format) : "",
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}></Col>

                <Col span={8}></Col>

                <Col span={8}></Col>
              </Row>
            </Form>
            <MenuForm handleMenuChange={handleMenuChange} />
            {showLocationPicker && (
              <LocationPicker
                onClose={handleCloseLocationPicker}
                onSelectLocation={handleSelectLocation}
              />
            )}
          </>
        );

      case 2:
        return (
          <>
            <InformationsComplementaires
              annonceData={annonceData}
              setAnnonceData={setAnnonceData}
            />
          </>
        );
      default:
        return (
          <Result
            icon={<CheckCircleOutlined style={{ color: "#d7a022" }} />}
            title="Votre annonce est publiée avec succès !"
            subTitle={
              <Space
                direction="vertical"
                size="small"
                style={{ marginBottom: "50px" }}
              >
                <Typography variant="body2">
                  Félicitations ! Votre annonce est maintenant visible pour tous
                  les utilisateurs.
                </Typography>
                <Typography variant="body2">
                  Vous pouvez la consulter{" "}
                  <Link
                    to="/mes-annonces"
                    style={{
                      color: "#d7a022",
                      textDecoration: "underline",
                    }}
                  >
                    ici
                    <EyeOutlined style={{ marginLeft: "4px" }} />
                  </Link>
                </Typography>
              </Space>
            }
          />
        );
    }
  };

  return (
    <div
  >
      <NavHote></NavHote>
      <div
        style={{
          marginTop:  "180px",
          textAlign: "left",
          marginLeft: "150px",

          marginBottom: "15px",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            lineHeight: "36px",
            textAlign: "left",
          }}
        >
          Déposer une nouvelle annonce
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          Suivez attentivement toutes les étapes et trouvez votre premier client
          en un clin d'œil!
        </Typography>
      </div>
      <Container style={{ padding: "100px", marginTop: "-90px" }}>
        <Container
          style={{
            border: "1px solid #B0B0B0",
            padding: "60px",
            backgroundColor: "#FAFAFA",
            borderRadius: "15px",
          }}
        >
          <StepperContainer activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepButtonStyled onClick={handleStep(index)}>
                  {label}
                </StepButtonStyled>
              </Step>
            ))}
          </StepperContainer>
          <Box sx={{ mt: 2 }}>{renderStepContent(activeStep)}</Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {!finishe && (
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, textTransform: "none", fontFamily: "Poppins" }}
              >
                Retour
              </Button>
            )}

            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep < steps.length - 1 && (
              <CustomButton onClick={handleNext} sx={{ mr: 1 }}>
                Suivant
              </CustomButton>
            )}
            {activeStep === steps.length - 1 && (
              <CustomButton
                onClick={() => {
                  handleComplete();
                  handleCompletion();
                }}
              >
                Terminer
              </CustomButton>
            )}
          </Box>
        </Container>
      </Container>
      <Footer></Footer>
      
    </div>
  );
}
